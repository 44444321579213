import { useNavigate } from "react-router-dom"
import { scrollTo } from "../utils/animateScroll";
import { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { useMediaQuery } from "react-responsive";

const partners = [
	"thena.svg", "raydium.svg", "velodrome.svg", "uniswap.svg", "jupiter.svg", "sushiswap.svg",
];

export default function LandingScene() {
    const navigate = useNavigate();

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const [openMenu, setOpenMenu] = useState(false);

    const scrollToSection = (id: any) => {
        scrollTo({ id });
        handleCloseMenu();
    }

    const handleOpenMenu = () => {
        setOpenMenu(true);
    }
    const handleCloseMenu = () => {
        setOpenMenu(false);
    }
    return (
        <div className="bg-[#111111] ">
            <div className="!fixed top-0 left-0 w-full   z-50 py-6 lg:py-8  px-3">
                <div className="container flex justify-between items-center">
                    <div className="flex-1">
                        <a href="/" className="flex items-center gap-2">
                            <img className="w-14" src="/images/logoCircle.png" alt="logo-mobile" />
                            <span className="text-[#ffc000] text-[24px] font-semibold hidden lg:block">Flash</span>
                            <span className="text-white text-[24px]  font-semibold hidden lg:block">Dex</span>
                        </a>
                    </div>
                    <nav className="hidden lg:flex gap-10 text-[20px]">
                        <a onClick={() => scrollToSection("Home")} className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Home</a>
                        <a onClick={() => scrollToSection("Features")}
                            className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Features</a>
                        <a onClick={() => scrollToSection("Process")} className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Process</a>
                        <a onClick={() => scrollToSection("Testimonials")}
                            className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Testimonials</a>
                    </nav>
                    <div className="flex-1 hidden lg:flex justify-end">
                        <button type="button" onClick={() => navigate("/app")}
                            className="btnStartFlesh bg-accent px-8 py-3 rounded-lg font-medium hover:bg-accent-light transition-all active:bg-accent-dark">
                            Start with Flash Dex
                        </button>
                    </div>

                    <button onClick={handleOpenMenu}
                        className="btnOpenMenu bg-[#181818] rounded-full w-[44px] h-[44px] flex items-center justify-center active:bg-accent lg:hidden">
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1H17M1 7H17M1 13H17" stroke="white" stroke-width="1.75" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
            <section id="Home" className="px-4 sm:px-0 bg-[#0B0B0B] pt-28 lg:pt-72 bgImage">
                <div className="container animate-slideInTop">
                    <div className="max-w-[991px] m-auto flex flex-col gap-4 text-center items-center">
                        <h1 className="font-bold text-[32px] lg:text-[100px] leading-none">Trade Smarter with Flash Dex</h1>
                        <p className="text-[#7F7F7F] lg:text-xl max-w-[661px] font-normal"> An aggregator supporting the best rates across
                            Raydium, Uniswap, LFJ, Thena, Jupiter, and more.</p>
                        <button type="button" onClick={() => navigate("/app")}
                            className="btnStartFlesh mt-4 bg-accent px-8 py-3 rounded-lg font-medium hover:bg-accent-light active:bg-accent-dark transition-all">
                            Start with Flash Dex
                        </button>
                    </div>
                </div>
                <img className="w-full h-auto animate-slideInBottom" src="/images/dex.svg" alt="logo-mobile" />
            </section>
            <section className="px-4 py-3 sm:px-0 w-full border-t-[1px]  border-b-[1px] border-[#2A2A2A] relative">
                <div className="shadow-lg absolute left-0 bottom-[-1px] top-[-1px] w-1/3 z-10"
                    style={{ background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)" }}></div>
                <div className="flex gap-8 lg:gap-16 lg:justify-between items-center w-full px-5 lg:px-20 overflow-auto">
                    <Swiper
						className="w-full h-[40px] lg:h-[60px]"
						spaceBetween={isMobile ? 60 : 100}
						slidesPerView={'auto'}
						loop={true}
						modules={[Autoplay]}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
					>
						{partners.map((item, index) =>
							<SwiperSlide key={index} className='max-w-[60px] lg:max-w-[220px]'>
								<div className='w-[100px] lg:w-[200px] h-[40px] lg:h-[60px] flex justify-center items-center'>
                                    <img className="min-w-[100px] max-h-[60px]" src={`/images/${item}`} alt="logo-mobile" />
								</div>
							</SwiperSlide>
						)}
					</Swiper>
                </div>
                <div className="shadow-lg absolute right-0 bottom-[-1px] top-[-1px] w-1/3 z-10"
                    style={{ background: "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)" }}></div>
            </section>
            <section id="Features" className="px-4 sm:px-0 pt-28 lg:pt-40 bgImage">
                <div className="container">
                    <div className="flex flex-col text-center gap-4 items-center">
                        <div className="sectionTitle flex m-auto px-8 py-4 rounded-full">
                            <h4 className="font-normal m-0 lg:text-xl">Our Features</h4>
                            <div className="customBorder flex m-auto px-8 py-4 rounded-full"></div>
                        </div>
                        <h2 className="text-[24px] md:text-[64px]">Features for Smarter Trading</h2>
                        <h5 className="text-white opacity-50 md:text-[18px] max-w-[669px]">Experience seamless multi-chain trading, the best
                            rates, enhanced security, and reduced gas fees—all in one platform.</h5>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 lg:gap-10 mt-5 lg:mt-10">
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <div className="bg-accent-gradient flex items-center justify-center w-[72px] h-[72px] rounded-lg">
                                <img src="/images/multi-chain.svg" alt="multi-chain" />
                            </div>
                            <h4 className="md:text-[24px] font-bold mt-4 md:mt-8">Multi-chain Interoperability</h4>
                            <p className="text-[14px] md:text-[18px] opacity-50 mt-3 max-w-[558px]">Support for major blockchains: Bitcoin,
                                Base, Ethereum, Solana, Binance Smart Chain, Polygon, Avalanche, Arbitrum, Optimism, Fantom and more</p>
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <div className="bg-accent-gradient flex items-center justify-center w-[72px] h-[72px] rounded-lg">
                                <img src="/images/rates.svg" alt="rates" />
                            </div>
                            <h4 className="md:text-[24px] font-bold mt-4 md:mt-8">Best Rates Guaranteed</h4>
                            <p className="text-[14px] md:text-[18px] opacity-50 mt-3 max-w-[558px]">Maximize returns with intelligent
                                aggregation of popular DEXs.</p>
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <div className="bg-accent-gradient flex items-center justify-center w-[72px] h-[72px] rounded-lg">
                                <img src="/images/flash.svg" alt="flash" />
                            </div>
                            <h4 className="md:text-[24px] font-bold mt-4 md:mt-8">Integrated Flash Wallet</h4>
                            <p className="text-[14px] md:text-[18px] opacity-50 mt-3 max-w-[558px]">A native solution for fast, simplified
                                transactions optimized for all blockchains.</p>
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 lg:gap-10 mt-5 lg:mt-10">
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <div className="bg-accent-gradient flex items-center justify-center w-[72px] h-[72px] rounded-lg">
                                <img src="/images/Security.svg" alt="Security" />
                            </div>
                            <h4 className="md:text-[24px] font-bold mt-4 md:mt-8">Enterprise-Grade Security</h4>
                            <p className="text-[14px] md:text-[18px] opacity-50 mt-3 max-w-[558px]">Regular audits, advanced penetration
                                testing, and partnerships with industry leaders to guarantee the security of your transactions and assets.
                            </p>
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <div className="bg-accent-gradient flex items-center justify-center w-[72px] h-[72px] rounded-lg">
                                <img src="/images/Fees.svg" alt="Fees" />
                            </div>
                            <h4 className="md:text-[24px] font-bold text-2xl mt-4 md:mt-8">Reduced Gas Fees</h4>
                            <p className="text-[14px] md:text-[18px] opacity-50 mt-3 max-w-[558px]">Only the gas fees of the sending chain are
                                required, not the receiving chain. Through DEX aggregation and innovative transaction architecture, we save
                                you on the receiving chain, making your trades more cost-effective and efficient.</p>
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>

                    </div>
                </div>
            </section>
            <section id="Process" className="px-4 sm:px-0 pt-28 lg:pt-40 bgImage">
                <div className="container">
                    <div className="flex flex-col gap-4 justify-start items-start">
                        <div className="sectionTitle flex px-8 py-4 rounded-full ">
                            <h4 className="font-normal m-0 lg:text-xl">Three-Step Process</h4>
                            <div className="customBorder flex m-auto px-8 py-4 rounded-full"></div>
                        </div>
                        <h2 className="text-[24px] md:text-[64px]">How Flash Dex Works</h2>
                        <h5 className="text-white opacity-50 text-[14px] md:text-[18px] max-w-[669px]">Effortlessly trade across multiple
                            blockchains with Flash Dex’s intuitive wallet connection, token selection, and real-time rate aggregation.
                        </h5>
                    </div>
                    <div className="flex flex-col lg:flex-row mt-4 md:mt-10 gap-4 md:gap-10">
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <h4 className="md:text-[24px] font-bold">Connect Your Wallet</h4>
                            <p className="opacity-50 mt-3 text-[14px] md:text-[18px]">Start trading by securely connecting your Flash Wallet
                                or other supported wallets like MetaMask, WalletConnect, and Phantom.</p>
                            <img src="/images/yourWallet.svg" alt="Your Wallet" className="mt-0" />
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <h4 className="text-[24px] font-bold text-xl">Select Your Tokens and Blockchain</h4>
                            <p className="opacity-50 mt-3">Choose from supported blockchains:Ethereum, Base, Solana, Binance Smart Chain,
                                Polygon, Avalanche, Arbitrum, Optimism, Fantom and more</p>
                            <img src="/images/tokens.svg" alt="tokens" className="mt-10 lg:mt-24" />
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                        <div className="flex flex-1 flex-col p-4 lg:p-10 bg-card-gradient text-white rounded-2xl relative">
                            <h4 className="text-[24px] font-bold text-xl">Get the Best Rates</h4>
                            <p className="opacity-50 mt-3">Automatically save by real-time aggregation from DEXs (Uniswap, PancakeSwap,
                                Raydium, Jupiter, etc.).</p>
                            <img src="/images/BestRates.svg" alt="Best Rates" className="mt-10 lg:mt-24" />
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Testimonials" className="px-4 sm:px-0 pt-28 lg:pt-40 bgImage">
                <div className="container">
                    <div className="flex flex-col gap-4 text-center items-center">
                        <h2 className="text-[24px] md:text-[64px]">Client Testimonials</h2>
                        <h5 className="text-white opacity-50 md:text-[18px]">Hear from our satisfied users who have transformed their
                            experience with Flash staking.</h5>
                    </div>
                    <div className="flex flex-col md:flex-row mt-10 gap-10">
                        <div
                            className="flex flex-1 flex-col items-center p-5 lg:p-10 bg-card-gradient text-white rounded-2xl relative text-center">
                            <div className="flex items-center justify-center gap-10">
                                <img className="opacity-5 w-10 md:w-auto h-auto" src="/images/qt.svg" alt="qt" />
                                <div className="flex flex-col items-center text-center">
                                    <img className="w-16 h-16 md:w-20 md:h-20 rounded-full" src="/images/c1.png" alt="Wade Warren" />
                                    <p className="text-[12px] md:text-lg opacity-50 mt-4">Wade Warren</p>
                                </div>
                                <img className="opacity-5 w-10 md:w-auto h-auto" src="/images/qt2.svg" alt="qt" />
                            </div>
                            <h5 className="text-[14px] md:text-xl lg:text-3xl font-normal  mt-3 md:mt-6 max-w-[242px] md:max-w-[506px]">"Flash
                                Dex helped me save on every swap – a DeFi revolution!"</h5>
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>
                        <div
                            className="flex flex-1 flex-col items-center p-5 lg:p-10 bg-card-gradient text-white rounded-2xl relative text-center">
                            <div className="flex items-center justify-center gap-10">
                                <img className="opacity-5 w-10 md:w-auto h-auto" src="/images/qt.svg" alt="qt" />
                                <div className="flex flex-col items-center text-center">
                                    <img className="w-16 h-16 md:w-20 md:h-20 rounded-full" src="/images/c2.png" alt="Cameron Williamson" />
                                    <p className="text-[12px] md:text-lg opacity-50 mt-4">Cameron Williamson</p>
                                </div>
                                <img className="opacity-5 w-10 md:w-auto h-auto" src="/images/qt2.svg" alt="qt" />
                            </div>
                            <h5 className="text-[14px] md:text-xl lg:text-3xl font-normal mt-3 md:mt-6 max-w-[242px] md:max-w-[506px]">“Flash
                                Dex made my transactions easy thanks to gas fees on the outgoing chain only”</h5>
                            <div className="customBorderCard rounded-2xl"></div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="px-4 sm:px-0 pt-28 lg:pt-40 bgImage">
                <div className="container">
                    <div
                        className="bg-accent flex flex-col lg:flex-row p-6 justify-center items-center text-center lg:text-left  gap-20 rounded-2xl">
                        <div className="flex flex-col max-w-[257px] md:max-w-[574px]">
                            <h3 className="font-bold text-[24px] md:text-[64px] leading-none">Join the Flash Dex Community</h3>
                            <p className="mt-4 max-w-[478px] text-[14px] md:text-[16px]">Connect with us on Telegram, Twitter, and Discord to
                                stay updated and be part of the Flash Dex revolution.</p>
                            <div className="flex justify-center gap-5 mt-5">
                                <a href="/" target="_blank"
                                    className="w-[52px] h-[52px] flex items-center justify-center bg-[#111111] transition-all hover:bg-[#4c4c4c] active:bg-black rounded-full">
                                    <img src="/images/discord.svg" alt="discord" />
                                </a>
                                <a href="/" target="_blank"
                                    className="w-[52px] h-[52px] flex items-center justify-center bg-[#111111] transition-all hover:bg-[#4c4c4c] active:bg-black rounded-full">
                                    <img src="/images/telegram2.svg" alt="telegram" />
                                </a>
                                <a href="/" target="_blank"
                                    className="w-[52px] h-[52px] flex items-center justify-center bg-[#111111] transition-all hover:bg-[#4c4c4c] active:bg-black rounded-full">
                                    <img src="/images/x2.svg" alt="x" />
                                </a>
                            </div>
                        </div>
                        <img src="/images/joinFlesh.svg" alt="joinFlesh" />
                    </div>
                </div>
            </section>
            <div className="px-4 sm:px-10 pt-40 pb-6 md:pb-20  bgImage text-white">
                <div className="bg-[#181818] pt-6 md:pt-32  rounded-2xl max-w-[1800px] m-auto px-10">
                    <div className="container">
                        <div
                            className="flex flex-col md:flex-row items-center text-center md:text-left md:items-start pb-20 md:pb-40 gap-4 md:gap-5 lgl:gap-10 xxl:gap-28 flex-wrap">
                            <div className="max-w-[335px] text-center md:text-left">
                                <a href="/" className="flex flex-col lg:flex-row items-center gap-2">
                                    <img className="w-14" src="/images/logoCircle.png" alt="logo-mobile" />
                                    <div>
                                        <span className="text-[#ffc000] text-[24px] font-semibold">Flash</span>
                                        <span className="text-white text-[24px] font-semibold">Dex</span>
                                    </div>
                                </a>
                                <p className="text-[#B3B3B3] mt-6">
                                    We proudly collaborate with a diverse range of trusted partners to enhance your experience and provide
                                    seamless access to the best in crypto services.
                                </p>
                            </div>
                            <div className="flex-1">
                                <h5 className="text-xl">Product</h5>
                                <div className="flex flex-col gap-3 mt-5 text-[#B3B3B3]">
                                    <a href="https://www.flash-technologies.org/" className="hover:text-accent transition-all" target="_blank">Flash Technologies</a>
                                    <a href="https://flash-transfer.com/" className="hover:text-accent transition-all" target="_blank">Flash Transfer</a>
                                    <a href="https://flash-wallet.com/" className="hover:text-accent transition-all" target="_blank">Flash Wallet</a>
                                    <a href="https://flash-staking.com/" className="hover:text-accent transition-all" target="_blank">Flash Staking</a>
                                    <a href="https://flash-launch.com/" className="hover:text-accent transition-all" target="_blank">Flash Launchpad</a>
                                    <a href="https://flash-dead.com/" className="hover:text-accent transition-all" target="_blank">Flash Dead</a>
                                </div>
                            </div>
                            <div className="flex-1">
                                <h5 className="text-xl">Support</h5>
                                <div className="flex flex-col gap-3 mt-5 text-[#B3B3B3]">
                                    <a href="/" className="hover:text-accent transition-all" target="_blank">Documentation</a>
                                    <a href="/" className="hover:text-accent transition-all" target="_blank">Contact Us</a>
                                </div>
                            </div>
                            <div className="flex-1 md:text-left">
                                <h5 className="text-xl whitespace-nowrap">Social network</h5>
                                <div className="flex flex-col gap-3 mt-5 text-[#B3B3B3]">
                                    <a href="https://www.facebook.com/flashtechnologiesoff" className="hover:text-accent transition-all" target="_blank">Facebook</a>
                                    <a href="https://instagram.com/flash_token_off" className="hover:text-accent transition-all" target="_blank">Instagram</a>
                                    <a href="https://www.linkedin.com/company/flash-technologies-off/" className="hover:text-accent transition-all" target="_blank">LinkedIn</a>
                                </div>
                            </div>
                            <div className="flex-1 w-full md:min-w-[310px] md:max-w-[310px] md:text-left">
                                <h5 className="text-xl">Sign up to our newsletter</h5>
                                <div className="flex w-full items-start justify-start flex-col gap-3 mt-5 text-[#B3B3B3] text-left">
                                    <form className="w-full" action="">
                                        <div className="flex w-full items-center">
                                            <label className="w-full">
                                                <input placeholder="Enter your email"
                                                    className="w-full h-12 ps-4 pe-14 text-white bg-[#111111] rounded-lg focus:outline-none focus:ring-0 focus:border-none"
                                                    type="email" value="" />
                                            </label>
                                            <button type="button"
                                                className="bg-accent -ml-12 flex items-center justify-center h-10 w-10 rounded-lg hover:bg-accent-light transition-all active:bg-accent-dark">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.50352 0.863329C1.37852 0.910204 1.14415 1.07427 1.06211 1.17192C1.03477 1.20317 0.968364 1.31645 0.917583 1.42583C0.741802 1.7852 0.741802 1.79692 1.62461 5.32427C2.06602 7.08989 2.45274 8.57427 2.48399 8.62114C2.51524 8.66802 2.57383 8.72661 2.6168 8.75005C2.65977 8.77349 4.19102 8.95708 6.02305 9.16021C7.85508 9.36333 9.43321 9.53911 9.52696 9.55474C9.77696 9.58989 9.96055 9.77739 9.96055 10C9.96055 10.2227 9.77696 10.4102 9.52696 10.4454C9.43321 10.461 7.85508 10.6368 6.01915 10.8399C2.60118 11.2188 2.55821 11.2266 2.46446 11.4024C2.44102 11.4415 2.06211 12.918 1.62071 14.6836C0.745708 18.1915 0.741802 18.211 0.913677 18.5743C1.03086 18.8282 1.1793 18.9727 1.44102 19.0938C1.6168 19.1758 1.69102 19.1876 1.90977 19.1719C2.15977 19.1524 2.44102 19.0196 10.3668 15.1524C19.277 10.8047 18.8316 11.043 19.0504 10.5938C19.1481 10.3985 19.1598 10.3282 19.1598 10C19.1598 9.67192 19.1481 9.60161 19.0504 9.4063C18.8316 8.95708 19.277 9.19536 10.3668 4.8477L2.16758 0.847704L1.89415 0.835985C1.74571 0.828173 1.56993 0.839891 1.50352 0.863329Z"
                                                        fill="black" />
                                                </svg>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="border-t-[1px] py-6 border-[#232323] text-center">
                            <p className="text-[#B3B3B3]">© 2024 Flash Dex. All rights reserved.</p>
                        </div>
                    </div>
                </div>

            </div>

            <div
                className={`mobileMenu lg:hidden !fixed top-0 h-full bg-[#0B0B0B] bgImage z-50 w-full px-3 transition-all ${openMenu ? 'left-0' : 'left-[100%]'} `}>
                <div className="flex flex-col">
                    <div className="flex justify-between items-center py-6">
                        <a href="/">
                            <img className="lg:hidden w-12" src="/images/logoCircle.png" alt="logo-mobile" />
                        </a>

                        <button onClick={handleCloseMenu}
                            className="btnCloseMenu  bg-[#181818] rounded-full w-[44px] h-[44px] flex items-center justify-center active:bg-accent lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="w-[50%] h-auto" fill="#fff"
                                viewBox="0 0 16 16">
                                <path
                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex-1 flex flex-col mt-6 gap-5 text-center">
                        <a onClick={() => scrollToSection("Home")} className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Home</a>
                        <a onClick={() => scrollToSection("Features")}
                            className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Features</a>
                        <a onClick={() => scrollToSection("Process")} className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Process</a>
                        <a onClick={() => scrollToSection("Testimonials")}
                            className="cursor-pointer text-white opacity-50 transition-all hover:opacity-100 hover:text-accent">Testimonials</a>
                    </div>
                    <div className="flex-1 flex items-center justify-center mt-8">
                        <button type="button" onClick={() => navigate("/app")}
                            className="btnStartFlesh bg-accent px-8 py-3 rounded-lg font-medium hover:bg-accent-light transition-all active:bg-accent-dark">
                            Start with Flash Dex
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}