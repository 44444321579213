import { Route, Routes, BrowserRouter } from "react-router-dom";
import MainScene from "../pages/MainScene";
import LandingScene from "../pages/LandingScene";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LandingScene />}></Route>
                <Route path="/app/*" element={<MainScene />}></Route>
            </Routes>
        </BrowserRouter>
    )
}