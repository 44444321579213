import LogoImg from "../../../src/assets/images/logo.png"
import WalletConnectBtn from "./WalletConnectBtn"
import HistoryImg from "../../../src/assets/images/history.png"
import FlashImg from "../../../src/assets/images/flash-icon.png"
import { useMediaQuery } from 'react-responsive'

export default function Header() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 500px)' })

    return (
        <div className="!fixed top-0 left-0 w-full   z-50 py-6 lg:py-8  px-3">
            <div className="!absolute top-0 left-0 w-full z-50 py-4 lg:py-6 px-3 border-b-[1px] border-[#232323]">
                <div className="container flex justify-between items-center">
                    <div className="flex-1">
                        <a href="/" className="flex items-center gap-2">
                            <img className="w-14" src="/images/logoCircle.png" alt="logo-mobile" />
                            <span className="text-[#ffc000] text-[24px] font-semibold hidden lg:block">Flash</span>
                            <span className="text-white text-[24px]  font-semibold hidden lg:block">Dex</span>
                        </a>
                    </div>

                    <div>
                        <div className="border-[#282727] bg-[#1C1C1C] border-[1px] flex rounded-full p-1 items-center gap-2 w-[141px]">
                            <img className="w-10 h-auto" src="/images/logoCircle.png" alt="logoCircle" />
                            <span className="text-[#7F7F7F]">$0.023</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}